import { citiesSlice } from "./citiesSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import * as constants from "./constants";
import { swaggerClient } from "../../../../../api/swaggerApiConnect";
import { getCurrentUserOrganizationUnit, getCurrentAccessToken } from "../../../../user-access/organizationUnitStorage";
import { get } from "../../../../../api/requester";

const { actions } = citiesSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "Cities_Get",
  actions,
  constants.QPARAMS_CHANGED,
);

export const fetchRegionCitiesSwagger = defaultActions.fetchCustomItemsSwagger(
  "Regions_GetByIdCities",
  actions,
  "entitiesSelect",
  "actionsLoading",
  "totalCount",
  "selectError",
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("Cities_Get", actions);

export const fetchItemsCitiesHelpSwagger = defaultActions.fetchItemsSwagger(
  "Regions_GetByIdCities",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("Cities_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("Cities_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "Cities_Post",
  actions,
  defaultActions.convertCreateItemParams(["name", "nameLocal", "regionId"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("Cities_Put", actions);

export const getRegionCitiesSwagger = region => fetchItemsSwagger({ region });

export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);

export const getCountryFromRegionSwagger = regionId => async dispatch => {
  const operationID = "Regions_GetByIdCountry";

  const userOrgUnit = getCurrentUserOrganizationUnit();
  const response = await getCurrentAccessToken();

  const params = {
    Id: regionId,
    OrganizationUnit: userOrgUnit,
  };

  dispatch(actions.startCall({ callType: defaultActions.callTypes.list }));
  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(response => {
        const data = response.body.id;
        dispatch(actions.countryFromRegionsFetched(data));
      })
      .catch(errors => {
        const error = defaultActions.requestErrorHandling(errors, "Error occurred during fetching the item!");
        dispatch(actions.catchError({ error, callType: defaultActions.callTypes.error }));
      }),
  );
};

export const getCitiesFromRegionSwagger = regionId => fetchItemsCitiesHelpSwagger({ Id: regionId });



export const getCitiesSelect = (filter) => async (dispatch) => {
  try {
    dispatch(actions.setItemsSelectLoading(true));
    ///api/masterdata/regions/select
    const cities = await get('/api/masterdata/cities/select', {filter})
    dispatch(actions.setItemsSelect(cities));
  } catch (e) {
    const error = defaultActions.requestErrorHandling(e, "Error occurred during fetching the items!");
    dispatch(actions.catchError({ error, callType: defaultActions.callTypes.error }));
  } finally {
    dispatch(actions.setItemsSelectLoading(false));
  }
} 
