/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
//import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { LayoutSplashScreen } from "../_metronic/layout";
import i18n from "./lang/i18n";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';

function App({ store, basename }) {

  // useEffect(() => {
  //   if (process.env.REACT_ENABLE_INSPECTLET === 'true') {
  //     window.__insp = window.__insp || [];
  //     window.__insp.push(['wid', 1564417581]);
  //     var ldinsp = function(){
  //       if(typeof window.__inspld != "undefined") return;
  //       window.__inspld = 1;
  //       var insp = document.createElement('script');
  //       insp.type = 'text/javascript';
  //       insp.async = true;
  //       insp.id = "inspsync";
  //       insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=1564417581&r=' + Math.floor(new Date().getTime()/3600000);
  //       var x = document.getElementsByTagName('script')[0];
  //       x.parentNode.insertBefore(insp, x);
  //     };
  //     setTimeout(ldinsp, 0);
  //   }
  // }, [])

  return (
    /* Provide Redux store */
    <Provider store={store}>
      
      <React.Suspense fallback={<LayoutSplashScreen />}>
        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <BrowserRouter basename={basename}>
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          {/* Render routes with provided `Layout`. */}
            <Routes />
        </BrowserRouter>
      </React.Suspense>
  
    </Provider>
  );
}

export default withAITracking(reactPlugin, App);
