import { PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { get, post, put, del } from "../api/requester";
import { constructInitialFilters } from "../app/components/common/filters/utils";
import { getErrorMessage } from "./_helpers";

export interface IDefaultState<I, D, E> {
  itemsLoading: boolean;
  actionsLoading: boolean;
  dropDownLoading: boolean;
  itemsCount: number;
  items: I[];
  dropDownItems: D[];
  itemForEdit: E;
  error: any;
  errorAction: any;
  errorTitle: string;
  dropDownError: any;
  validationErrors: {};
  filters?: {};
}

// --------------------- Default State ---------------------

export const defaultInitialState: IDefaultState<any, any, any> = {
  itemsLoading: false,
  actionsLoading: false,
  dropDownLoading: false,
  itemsCount: 0,
  items: [],
  dropDownItems: [],
  itemForEdit: null,
  error: null,
  errorAction: null,
  errorTitle: '',
  dropDownError: null,
  validationErrors: {},
  filters: {},
};

// --------------------- Default Reducers ---------------------

export const defaultReducers = {
  startItemCall: (state: any, action: PayloadAction<any>) => {
    state.error = null;
    state.errorAction = null;
    state.errorTitle = '';
    state.dropDownError = null;
    state[action.payload.callType] = true;

    if (action.payload.callType === action.payload.callType.actionsLoading) {
      state.items = [];
      state.itemsCount = 0;
      state.itemForEdit = null;
      state.dropDownItems = [];
    }
  },
  setItemLoading: (state: any, action: PayloadAction<boolean>) => {
    state.actionsLoading = action.payload
  },
  endActionCall: (state: any) => {
    state.actionsLoading = false
  },
  startDropDownItemsCall: (state: any) => {
    state.dropDownLoading = true;
    state.dropDownItems = [];

    state.error = null;
    state.errorAction = null;
    state.errorTitle = '';
    state.dropDownError = null;
  },
  setItem: (state: any, action: PayloadAction<any>) => {
    state.actionsLoading = false;
    state.itemForEdit = action.payload;

    state.error = null;
    state.errorAction = null;
    state.errorTitle = '';
    state.dropDownError = null;
  },
  setItems: (state: any, action: PayloadAction<any>) => {
    state.items = action.payload.items;
    state.itemsCount = action.payload.itemsCount;
    state.itemsLoading = false;

    state.error = null;
    state.errorAction = null;
    state.errorTitle = '';
    state.dropDownError = null;
  },
  setDropDownItems: (state: any, action: PayloadAction<any>) => {
    state.dropDownItems = action.payload;
    state.dropDownLoading = false;

    state.error = null;
    state.errorAction = null;
    state.errorTitle = '';
    state.dropDownError = null;
  },
  catchError: (state: any, action: PayloadAction<any>) => {
    state[action.payload.errorProp] = action.payload.errors;
    state[action.payload.errorTitle] = action.payload.dialogTitle || '';
    state[action.payload.callType] = false;
  },
  clearAll: (state: any) => {
    state.itemsLoading = false;
    state.actionsLoading = false;
    state.itemsCount = 0;
    state.items = [];
    state.dropDownItems = [];
    state.itemForEdit = null;
    state.error = null;
    state.errorAction = null;
    state.errorTitle = '';
    state.dropDownError = null;
  },
  clearItem: (state: any) => {
    state.itemsLoading = false;
    state.actionsLoading = false;
    state.dropDownItems = [];
    state.itemForEdit = null;
  },
  clearErrors: (state: any) => {
    state.error = null;
    state.errorAction = null;
    state.errorTitle = '';
    state.dropDownError = null;
  },
  setValidationErrors: (state: any, action: PayloadAction<any>) => {
    state.validationErrors = action.payload;
  },
  setFilter: (state:any, action: PayloadAction<any>) => {
    state.filters = action.payload
  },
};


// --------------------- Default Actions ---------------------
export const setItemLoading = (actions:any, val:boolean) => (dispatch:Dispatch) => {
  dispatch(actions.setItemLoading(val));
}


/** 
 * @desc:     Get all Items (read)
 * @params:   url (API endpoint) 
 * @params:   actions (module actions)
 * @params:   params (query params)
 **/
export const getItems = (url: string, actions: any, params = {}, refineData:any = null) => async (dispatch: Dispatch) => {
  dispatch(actions.startItemCall({ callType: "itemsLoading" }));

  try {
    const res = await get(url, params);
    const items = res.items
      ? refineData
          ? refineData(res.items)
          : res.items
      : []; // Items Array
    const itemsCount = res.totalCount ? res.totalCount : 0; // Items Count

    dispatch(actions.setItems({ items, itemsCount }));
  } catch (error) {
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "itemsLoading", errorProp: "error" }));
  }
};

/** 
 * @desc:     Get Item (read)
 * @params:   url (API endpoint) 
 * @params:   actions (module actions)
 * @params:   refineData (only if the data must be changed)
 **/
export const getItem = (url: string, actions: any, refineData: any = null) => async (dispatch: Dispatch) => {
  dispatch(actions.startItemCall({ callType: "actionsLoading" }));

  try {
    const res = await get(url);
    // If for some reason the response data must be restructured or changed in any way (like tabs nesting)
    const refinedData = refineData ? refineData(res) : res;

    dispatch(actions.setItem(refinedData));
  } catch (error) {
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "actionsLoading", errorProp: "errorAction" }));
  }
};

/** 
 * @desc:     Save Item (create/update)
 * @params:   url (API endpoint) 
 * @params:   actions (module actions)
 * @params:   item (item object)
 * @params:   id (only for update)
 * @params:   refineData (only if the data must be changed)
 **/
export const saveItem = (url: string, actions: any, item = {}, id: string | null, refineData: any = null, contentType="application/json") => async (dispatch: Dispatch) => {
  dispatch(actions.startItemCall({ callType: "actionsLoading" }));

  // If for some reason the request data must be restructured or changed in any way
  const refinedData = refineData ? refineData(item) : item;

  try {
    if(id) {
      await put(url, {}, refinedData, {contentType});
    } else {
      await post(url, {}, refinedData, {contentType});
    }
    dispatch(actions.endActionCall());
    return true;
  } catch (error) {
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "actionsLoading", errorProp: "errorAction" }));
  }
};

/** 
 * @desc:     Remove Item (delete)
 * @params:   url (API endpoint) 
 * @params:   actions (module actions)
 **/
export const deleteItem = (url: string, actions: any) => async (dispatch: Dispatch) => {
  dispatch(actions.startItemCall({ callType: "actionsLoading" }));

  try {
    await del(url);
    dispatch(actions.endActionCall());
    return true;
  } catch (error) {
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "actionsLoading", errorProp: "errorAction" }));
  }
};

/** 
 * @desc:     Get Items for drop downs (read)
 * @params:   url (API endpoint) 
 * @params:   actions (module actions)
 * @params:   params (query params)
 **/
export const getDropDownItems = (url: string, actions: any, params = {}) => async (dispatch: Dispatch) => {
  dispatch(actions.startDropDownItemsCall());
  try {
    const res = await get(url, params);
    dispatch(actions.setDropDownItems(res ? res : []));
  } catch (error) {
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "dropDownLoading", errorProp: "error" }));
  }
};

/** 
 * @desc:     Get Available filters of certain type for complex search (read)
 * @params:   url (API module base. No need to add complexSearch) 
 * @params:   actions (module actions)
 **/
export const getFilters = (url: string, actions:any) => async (dispatch:Dispatch) => {
  try {
    const res: any = await get(`${url}/complexSearch`);
    dispatch(actions.setFilter(constructInitialFilters(res)));
  } catch (error: any) {
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "actionsLoading", errorProp: "error" }));
  }
}

export const setFilters = (filters: any, actions:any) => (dispatch:Dispatch) => {
  dispatch(actions.setFilter(filters));
}

/** 
 * @desc:     Reset module State (internal)
 **/
export const clearAll = (actions: any) => (dispatch: Dispatch) => {
  dispatch(actions.clearAll());
};

/** 
 * @desc:     Reset Item State (internal)
 **/
export const clearItem = (actions: any) => (dispatch: Dispatch) => {
  dispatch(actions.clearItem());
};

/** 
 * @desc:     Reset all type of Errors (internal)
 **/
export const clearErrors = (actions: any) => (dispatch: Dispatch) => {
  dispatch(actions.clearErrors());
};
