import { taskTypesSlice } from "./taskTypesSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import * as constants from "./constants";

export const QPARAMS_CHANGED = "taskTypes/QParamsChanged";
const { actions } = taskTypesSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "TaskTypes_Get",
  actions,
  QPARAMS_CHANGED,
);

export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "TaskTypes_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("TaskTypes_Get", actions);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("TaskTypes_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("TaskTypes_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "TaskTypes_Post",
  actions,
  defaultActions.convertCreateItemParams(["code", "name",  "order",  "nameLocal", "description", "active", "taskCategoryId"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("TaskTypes_Put", actions);

export const getCategoryTaskTypes = category => fetchItemsSwagger({ category: category });

export const fetchTaskTypeSurveysSwagger = defaultActions.fetchCustomItemsSwagger(
  "TaskTypes_GetTaskTypeSurveys",
  actions,
  constants.TASK_TYPE_SURVEYS,
  constants.TASK_TYPE_SURVEYS_LOADING,
  constants.TASK_TYPE_SURVEYS_COUNT,
);

export const clearTaskSurveys = () => actions.clearTaskSurveys()

export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);
