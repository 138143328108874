import { surveysSlice } from "./surveysSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import { SURVEYS_QPARAMS_CHANGED } from "./constants";
import { swaggerClient } from "../../../../../api/swaggerApiConnect";
import { getCurrentUserOrganizationUnit, getCurrentAccessToken } from "../../../../user-access/organizationUnitStorage";

const { actions } = surveysSlice;

const callTypes = {
  surveyAnswerTypes: "surveyAnswerTypesLoading",
  surveyAnswerSubTypes: "surveyAnswerSubTypesLoading",

  surveyArticlesLoading: 'surveyArticlesLoading',

  surveyDynamicOperators: "surveyDynamicOperatorsLoading",
  taskTypes: "surveyTaskTypesLoading",
  action: "actionsLoading",
};

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "Surveys_Get",
  actions,
  SURVEYS_QPARAMS_CHANGED,
);


export const setValidationErrors = actions.setValidationErrors;

export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("Surveys_Get", actions);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("Surveys_GetById", actions);

export const createItemSwagger = defaultActions.createItemSwagger("Surveys_Post", actions, item => {
  return {
    surveyIdentifier: item.surveyIdentifier,
    id: item.id,
    version: 1, //item.version,
    languageId: item.languageId,
    description: item.description,
    validFromUTC: item.validFromUTC,
    validToUTC: item.validToUTC,
    timeZone: null, //item.timeZone
    enableCopyLast: item.enableCopyLast,
    isDefault: item.isDefault,
    isScoring: item.isScoring,
    isSuppressed: item.isSuppressed,
    sections: item.sections.map(section => ({
      questions: section.questions.map(question => ({
        surveyDynamicQuestionsDependencies: question.surveyDynamicQuestionsDependencies?.map(dependency => ({
          surveyConditionalQuestionId: dependency.surveyConditionalQuestionId,
          surveyDependantQuestionId: dependency.surveyDependantQuestionId,
          surveyDependantAnswerValueId: dependency.surveyDependantAnswerValueId,
          surveyDynamicOperatorId: dependency.surveyDynamicOperatorId,
          value: dependency.value,
        })),
        id: question.id,
        surveyAnswerTypeId: question.surveyAnswerTypeId,
        surveyAnswerSubTypeId: question.surveyAnswerSubTypeId,
        surveySectionId: section.surveyHeaderId,
        surveyQuestionIdentifier: question.surveyQuestionIdentifier,
        description: question.description,
        defaultValue: question.defaultValue !== "" ? question.defaultValue : null,
        isRequired: question.isRequired,
        min: question.min,
        max: question.max,
        isReadOnly: question.isReadOnly,
        sequence: question.sequence,
        copyLastEnabled: question.copyLastEnabled,
        score: question.score,
        isDynamic: question.isDynamic,
        enableAttachment: question.enableAttachment,
        requireAttachment: question.requireAttachment,
        surveyAnswerValues: question.surveyAnswerValues?.map(answerValue => ({
          id: answerValue.id,
          surveyQuestionId: answerValue.surveyQuestionId,
          value: answerValue.value,
          weight: answerValue.weight,
          order: answerValue.order,
          isSelected: answerValue.isSelected,
          articleId: answerValue.articleId?.id
        })),
      })),
      id: section.id,
      surveyHeaderId: section.surveyHeaderId,
      parentId: section.parentId,
      sequence: section.sequence,
      surveySectionIdentifier: section.surveySectionIdentifier,
      description: section.description,
      surveySectionTypeId: section.surveySectionTypeId,
    })),
    taskTypes: item.taskTypes?.map(taskType => ({
      id: taskType.id,
      surveyId: taskType.surveyId,
      taskTypeId: taskType.taskTypeId,
      isMandatory: taskType.isMandatory,
    })),
  };
});

export const deleteItemSwagger = defaultActions.deleteItemSwagger("Surveys_Delete", actions);

export const clear = () => defaultActions.clear(actions);

export const getTaskTypesSwagger = () => async dispatch => {
  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  let operationID = "SurveyTaskTypes_GetTaskTypes";
  let params = {
    OrganizationUnit: userOrgUnit,
  };

  dispatch(actions.startCall({ callType: callTypes.taskTypes }));
  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(response => {
        const data = response.body.map(en => {
          return { ...en, assigned: false };
        });
        dispatch(
          actions.taskTypesFetched({
            data,
            callType: callTypes.taskTypes,
          }),
        );
      })
      .catch(error => {
        error.clientMessage = `Can't load survey task types`;
        dispatch(actions.catchError({ error, callType: defaultActions.callTypes.error }));
      }),
  );
};

export const getSurveyAnswerTypesSwagger = () => async dispatch => {
  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  let operationID = "SurveyQuestions_GetSurveyAnswerTypes";
  let params = {
    OrganizationUnit: userOrgUnit,
  };

  dispatch(actions.startCall({ callType: callTypes.surveyAnswerTypes }));
  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(response => {
        const data = response.body;
        dispatch(actions.surveyAnswerTypesFetched({ data, callType: callTypes.surveyAnswerTypes }));
      })
      .catch(error => {
        error.clientMessage = `Can't load survey task types`;
        dispatch(actions.catchError({ error, callType: defaultActions.callTypes.error }));
      }),
  );
};

export const getSurveyAnswerSubTypesSwagger = (AnswerTypeId) => async dispatch => {
  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  let operationID = "SurveyQuestions_GetSelect";
  let params = {
    OrganizationUnit: userOrgUnit,
    AnswerTypeId
  };

  dispatch(actions.startCall({ callType: callTypes.surveyAnswerSubTypes }));
  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(response => {
        const data = response.body;
        dispatch(actions.surveyAnswerSubTypesFetched({ data, callType: callTypes.surveyAnswerSubTypes }));
      })
      .catch(error => {
        error.clientMessage = `Can't load survey task sub-types`;
        dispatch(actions.catchError({ error, callType: defaultActions.callTypes.error }));
      }),
  );
}

export const getSurveyArticlesList = (query) => async dispatch => {
  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  let operationID = "Articles_GetArticles";
  let params = {
    OrganizationUnit: userOrgUnit,
    Filter: query.filter
  };

  dispatch(actions.surveyArticlesLoading({ data: {qIndex: query.qIndex}, callType: callTypes.surveyArticlesLoading }));
  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(response => {
        const data = {list: response.body, qIndex: query.qIndex};
        dispatch(actions.surveyArticlesFetched({ data, callType: callTypes.surveyArticlesLoading }));
      })
      .catch(error => {
        error.clientMessage = `Can't load articles`;
        dispatch(actions.catchError({ error, callType: defaultActions.callTypes.error }));
      }),
  );
}


export const getSurveyDynamicOperatorsSwagger = () => async dispatch => {
  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  let operationID = "SurveyQuestions_GetSurveyDynamicOperator";
  let params = {
    OrganizationUnit: userOrgUnit,
  };
  dispatch(actions.surveyDynamicOperators({ callType: callTypes.surveyDynamicOperators }));
  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(response => {
        const data = response.body;

        dispatch(actions.surveyDynamicOperators({ data, callType: callTypes.surveyDynamicOperators }));

        return data;
      })
      .catch(error => {
        error.clientMessage = `Can't load question for edit`;
        dispatch(actions.catchError({ error, callType: defaultActions.callTypes.error }));
      }),
  );
};

export const getSurveyVersionsSwagger = id => async dispatch => {
  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  let operationID = "Surveys_GetVersionsById";

  let params = {
    Id: id,
    OrganizationUnit: userOrgUnit,
  };

  //dispatch(actions.startCall({ callType: callTypes.taskTypes }));
  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(response => {
        const data = response.body;

        dispatch(actions.surveysVersions({ data }));
      })
      .catch(error => {
        error.clientMessage = `Can't load survey versions`;
        dispatch(actions.catchError({ error, callType: defaultActions.callTypes.error }));
      }),
  );
};

export const updateItemSwagger = (id, allFormValuesToSave) => async dispatch => {
  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  let operationID = "Surveys_Put";
  let params = {
    OrganizationUnit: userOrgUnit,
  };

  dispatch(actions.startCall({ callType: callTypes.action }));

  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        requestBody: allFormValuesToSave,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(response => {
        var item = response.body;
        let updatedItem = item.savedSurveyData;
        dispatch(actions.itemUpdated({ updatedItem }));
        return item;
      })
      .catch(err => {
        let error = defaultActions.requestErrorHandling(err, "Error occurred during updating the item!");
        dispatch(actions.catchError({ error, callType: callTypes.action, errorProp: "error"}));
      }),
  );
};

export const setItemForEdit = (item) => dispatch => {
  dispatch(actions.itemFetched({ itemForEdit: item }));
}