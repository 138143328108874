import { RootState } from "../../../../../redux/store";
import * as constants from "./constants";

export const isItemsLoading = (state:RootState) => state.regions.listLoading;
export const isItemActionLoading = (state:RootState) => state.regions.actionsLoading;
export const getItems = (state:RootState) => state.regions.entities;
export const getItemsSelect = (state:RootState) => state.regions.entitiesSelect;
export const getItemsSelectLoading = (state:RootState) => state.regions.entitiesSelectLoading;
export const getItemsCount = (state:RootState) => state.regions.totalCount;
export const getItemForEdit = (state:RootState) => state.regions.itemForEdit;
export const getError = (state:RootState) => state.regions.error;
export const validationErrors = (state: RootState) => state.regions.validationErrors;

export const getRegionCities = (state:RootState) => state.regions[constants.REGIONS_CITIES];
export const getRegionCitiesCount = (state:RootState) => state.regions[constants.REGIONS_CITIES_COUNT];
export const getRegionCitiesLoading = (state:RootState) => state.regions[constants.REGIONS_CITIES_LOADING];