import { PayloadAction } from "@reduxjs/toolkit";
import { getDefaultSlice } from "../../../../../redux/_helpers";

const customReducers = {
    setItemsSelectLoading: (state:any, action:PayloadAction) => {
      state.entitiesSelectLoading = action.payload;
    },
    setItemsSelect: (state:any, action:PayloadAction) => {
      state.entitiesSelect = action.payload;
    }
};

const initiaState = {};

export const regionsSlice = getDefaultSlice("regions", customReducers, initiaState);
