// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { FormFieldsProvider } from "../../../controls/forms/formFields/FormFieldsContext";
import { useAppDispatch } from "../../../../../app/common/_customHooks/redux";

const getMergedSchema = chlildrenArray => {
  let schema = Yup.object();
  chlildrenArray.forEach(child => {
    if (child.props.validationSchema) schema = schema.concat(child.props.validationSchema);
  });
  return schema;
};

export function EditTabForm({
  item,
  saveItem,
  btnRef,
  btnResetRef,
  initialTouched,
  validationSchema,
  selectedTabIndex,
  onTabChange,
  isWizard,
  viewOnly = false,
  isEdit,
  children,
  setValidationErrors,
  validationErrorTabIndex,
  saveClicked = false,
  setSaveClicked,
  setDirty,
  onSubmit,
  onKeyPress,
  formCustomClass = '',
  showTabbar = true,
  valuesUpdated,
}) {
  const dispatch = useAppDispatch();
  
  const [showErrorsDebug, setShowErrorsDebug] = useState(false);

  const chlildrenArray = React.Children.toArray(children) || [];
  const formValidationSchema = validationSchema || getMergedSchema(chlildrenArray);
  // Tabs
  const [tab, setTab] = useState(selectedTabIndex || 0);
  const [touchedTabs, setTouchedTabs] = useState([]);

  const [disabledFields, setDisabledFields] = useState([]);
  const setDisabled = field => {
    if (!disabledFields.includes(field)) setDisabledFields([...disabledFields, field]);
  };
  const setEnabled = field => {
    const res = disabledFields.filter(x => x !== field);
    setDisabledFields(res);
  };
  const isDisabled = name => disabledFields.includes(name);

  const [hiddenFields, setHiddenFields] = useState([]);
  const setHidden = field => {
    if (!hiddenFields.includes(field)) setHiddenFields([...hiddenFields, field]);
  };
  const setVisible = field => {
    const res = hiddenFields.filter(x => x !== field);
    setHiddenFields(res);
  };
  const isHidden = name => hiddenFields.includes(name);

  useEffect(() => {
    if (selectedTabIndex !== tab) onTabClick(selectedTabIndex)
  }, [selectedTabIndex])

  const nextHandler = values => {
    const currentTab = chlildrenArray[tab];
    if (currentTab.props.validationSchema) {
      currentTab.props.validationSchema.isValid(values).then(valid => {
        if (valid) {
          setTab(tab + 1);
          onTabChange && onTabChange(tab + 1);
        }
      });
    } else {
      setTab(tab + 1);
      onTabChange && onTabChange(tab + 1);
    }
    setTouchedTabs([...touchedTabs, tab]);
  };

  const onTabClick = idx => {
    if (!isWizard || isEdit) {
      setTab(idx);
      onTabChange && onTabChange(idx);
    } else {
    }
  };

  useEffect(() => {
    if (saveClicked && !isNaN(validationErrorTabIndex)) {
      setTab(validationErrorTabIndex || 0);
      if (setSaveClicked && onTabChange) {
        onTabChange(validationErrorTabIndex || 0);
        setSaveClicked(false)
      }

    }
  }, [validationErrorTabIndex, saveClicked]);

  function renderTabs() {
    return (
      <>
        {chlildrenArray.map((child, idx) => (
          <li className="nav-item modal-nav-item" onClick={() => onTabClick(idx)} key={idx}>
            <p
              className={`nav-link cursor-pointer ${tab === idx && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === idx).toString()}
            >
              {isWizard && !isEdit && (
                <span className={`tabs-badge ${tab === idx ? "tabs-badge-selected" : ""}`}>{idx + 1}</span>
              )}
              {child.props.title}
            </p>
          </li>
        ))}
      </>
    );
  }

  const formikUseEffect = useEffect;

  return (
    <>
      {(chlildrenArray.length > 1 && chlildrenArray[0].props?.title) && <ul className="nav nav-tabs nav-pills grey-nav-pills align-items-center" role="tablist">
        {showTabbar && renderTabs()}
      </ul>}
      <div className={`pt-5 hm-100 ${formCustomClass}`}>
        <Formik
          enableReinitialize
          initialValues={item}
          validationSchema={formValidationSchema}
          initialErrors={{}}
          initialTouched={initialTouched}
          {...(onKeyPress ? {onKeyPress} : {})}
          {...(onSubmit ? {onSubmit: (values, actions) => onSubmit(values, actions, setDirty)} : {})}
        >
          {({ handleReset, values, setValues, errors, touched, setFieldTouched, dirty, ...props }) => {
            // this useEffect prevents state change attempts after the form component unmounted
            formikUseEffect(() => {
               // the "if" prevent form reset on initial values change or when initial data is fetched from API and it heeds to wait to reset dirty flag
              if(dirty && Object.keys(touched).length !== 0) {
                setDirty(dirty);
              }
              if(Object.keys(touched).length) setValidationErrors && dispatch(setValidationErrors(errors));
            }, [dirty, errors, touched, dispatch]);

            formikUseEffect(() => {
              valuesUpdated && valuesUpdated(values);
            }, [values])

            return (
              <>
                <Form {...(onKeyPress ? {onKeyPress} : {})} className={`${viewOnly ? "not-allowed" : null} form form-label-right h-100`} data-testid="tab-form">
                  <FormFieldsProvider
                    formProps={{
                      isDisabled,
                      setDisabled,
                      setEnabled,
                      isHidden,
                      setHidden,
                      setVisible,
                    }}
                  >
                    {chlildrenArray[tab] && React.cloneElement(chlildrenArray[tab], {
                      ...chlildrenArray[tab].props,
                      isTabTouched: touchedTabs.includes(tab),
                    })}
                    <button
                      type="submit"
                      data-testid="tab-form-submit-btn"
                      style={{ display: "none" }}
                      ref={btnRef}
                      onClick={() =>  {
                        if(Object.keys(errors).length) return
                        setValues(values); // Updates Formik's internal state
                        saveItem(values)
                      }}
                    ></button>
                    <button style={{ display: "none" }} ref={btnResetRef} onClick={handleReset}></button>
                  </FormFieldsProvider>
                </Form>
                <div>
                  {isWizard && !isEdit && (
                    <>
                      {tab > 0 && (
                        <button type="button" className="btn btn-light mr-10" onClick={() => {
                          setTab(tab - 1)
                          onTabChange && onTabChange(tab + 1);
                        }}>
                          <i className="fa fa-angle-left"></i> Previous
                        </button>
                      )}
                      {chlildrenArray && tab < chlildrenArray.length - 1 && (
                        <button type="button" className="btn btn-light" data-testid="next-btn" onClick={() => nextHandler(values)}>
                          Next{"  "}
                          <i className="fa fa-angle-right"></i>
                        </button>
                      )}
                    </>
                  )}
                </div>
                {showErrorsDebug && (
                  <DebugFields errors={errors} touched={touched} disabledFields={disabledFields} />
                )}
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

const DebugFields = ({ errors, touched, disabledFields }) => {
  return (
    <>
      <div style={{ margin: "1rem 0" }}>
        <pre
          style={{
            background: "#f6f8fa",
            fontSize: ".85rem",
            padding: ".5rem",
          }}
        >
          <strong>values</strong> = {JSON.stringify({ errors, touched }, null, 2)}
        </pre>
      </div>
      <div style={{ margin: "1rem 0" }}>
        <pre
          style={{
            background: "#f6f8fa",
            fontSize: ".85rem",
            padding: ".5rem",
          }}
        >
          <strong>disabled</strong> = {JSON.stringify({ disabledFields }, null, 2)}
        </pre>
      </div>
    </>
  );
}
