import { Dispatch } from "@reduxjs/toolkit";
import { routePlanningSlice } from "./routePlanningSlice";
import * as defaultActions from "../../../../../redux/_common";
import { get, getHere, post, postHere, put  } from "../../../../../api/requester";
import { getErrorMessage } from "../../../../../redux/_helpers";
import { ILocation, ITask, ITaskFilterTab } from "../../_interfaces/routeCreation";
import axios from "axios";
import { ITour, IToursPlan } from "../../_interfaces/reduxState";
import { ROUTE_COLORS } from "../../constants";
import * as fields from '../../constants';


const moduleUrl = '/api/routeplanning';
const { actions } = routePlanningSlice;
export const setValidationErrors = actions.setValidationErrors;

export const getItems = (params:any) => {
    return defaultActions.getItems(moduleUrl, actions, params);
}


export const clearAll = () => defaultActions.clearAll(actions);
export const clearRouteItem = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);


export const getRangeOptions = () => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setRangeOptionsLoading(true));

        const ranges = [
            {
                id: '1',
                code: 'TER',
                name: 'Territory'
            },
            {
                id: '2',
                code: 'CITY',
                name: 'City'
            },
            {
                id: '3',
                code: 'REG',
                name: 'Region'
            },
            {
                id: '4',
                code: 'CO',
                name: 'Country'
            }
        ]
        // const ranges = await get(``)

        dispatch(actions.setRangeOptions(ranges));
    } catch (errors:any) {
        const error = getErrorMessage(errors, "Error occured while fetching route ranges!");
        dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
    } finally {
        dispatch(actions.setRangeOptionsLoading(false));
    }
}

// export const getTerritoryOptions = (params:{range:string}) => async (dispatch:Dispatch) => {
//     try {
//         dispatch(actions.setTerritoryLoading(true));

//         // const territories = await get('', params);
//         const territories = [{
//             id: '111',
//             name: 'Territory 1'
//         }];

//         dispatch(actions.setTerritoryOptions(territories));
//     } catch (errors:any) {
//         const error = getErrorMessage(errors, "Error occured while fetching territories!");
//         dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
//     } finally {
//         dispatch(actions.setTerritoryLoading(false));
//     }
// }




// const locations:ILocation[] = [
//     {
//         id: 'a',
//         address: 'SPZ Slatina, Blvd "Asen Yordanov" 7, 1574 Sofia, Bulgaria',
//         description: 'Kaufland Slatina',
//         location: [42.6634255,23.2224589],
//         isActive: true,
//         tasks: new Array(2).fill({
//             id: 'a',
//             taskType: 'Customer Development',
//             address: 'SPZ Slatina, Blvd "Asen Yordanov" 7, 1574 Sofia, Bulgarien',
//             description: 'Kaufland Slatina',
//             duration: 15,
//             taskCode: 'CDE',
//             location: [42.6634255,23.2224589],
//             isActive: true,
//         }).map((t, i) => ({...t, id: `${t.id} + ${i+1}`}))
//     },
//     {
//         id: 'b',
//         address: 'g.k. Mladost 3Mladost, ul. "Filip Avramov" 3, 1712 Sofia, Bulgarien',
//         description: 'Kaufland Mladost',
//         location: [42.645387,23.3593959],
//         isActive: true,
//         tasks: new Array(4).fill({
//             id: 'b',
//             taskType: 'Customer Development',
//             address: 'g.k. Mladost 3Mladost, ul. "Filip Avramov" 3, 1712 Sofia, Bulgarien',
//             description: 'Kaufland Mladost',
//             duration: 15,
//             taskCode: 'MSA',
//             location: [42.645387,23.3593959],
//             isActive: true,
//         }).map((t, i) => ({
//             ...t,
//             id: `${t.id} + ${i+1}`,
//             taskCode: i % 2 === 0 ? 'MSA' : 'VIS',
//             taskType: i % 2 === 0 ? 'Market Scan' : 'Visit',
//         }))
//     },
//     {
//         id: 'c',
//         address: 'bulevard "General Eduard I. Totleben" 36, 1606 Sofia, Bulgarien',
//         description: 'Kaufland - Kriva reka, Sofia',
//         location: [42.6885777,23.2999764],
//         isActive: true,
//         tasks: new Array(1).fill({
//             id: 'c',
//             taskType: 'MAINTENANCE',
//             taskCode: 'EM',
//             address: 'bulevard "General Eduard I. Totleben" 36, 1606 Sofia, Bulgarien',
//             description: 'Kaufland - Kriva reka, Sofia',
//             duration: 10,
//             location: [42.6885777,23.2999764],
//             isActive: true,
//         }).map((t, i) => ({...t, id: `${t.id} + ${i+1}`}))
//     },
//     {
//         id: 'd',
//         address: 'Manastirski Livadi, ul. "Vasil Stefanov" 61, 1404 Sofia, Bulgarien',
//         description: 'Kaufland Sofia-Manastirski livadi',
//         location: [42.6554401,23.2829972],
//         isActive: true,
//         tasks: new Array(3).fill({
//             id: 'd',
//             taskType: 'MAINTENANCE',
//             taskCode: 'EM',
//             address: 'Manastirski Livadi, ul. "Vasil Stefanov" 61, 1404 Sofia, Bulgarien',
//             description: 'Kaufland Sofia-Manastirski livadi',
//             duration: 20,
//             location: [42.6554401,23.2829972],
//             isActive: true,
//         }).map((t, i) => ({...t, id: `${t.id} + ${i+1}`}))
//     }
// ];

export const getLocations = (params:ITaskFilterTab) => async (dispatch:Dispatch) => {
    try {
        const transformedParams = {
            includeAssigned: Boolean(params[fields.INCLUDE_ASSIGNED]),
            Date: params[fields.DATE]
                ? new Date(params[fields.DATE]).toISOString()
                : new Date().toISOString(),
            ...(params[fields.RANGE]?.id === '1' && params[fields.TERRIOTRY]
                    ? {TerritoryId: params[fields.TERRIOTRY]?.id}
                    : {}
            ),
            ...(params[fields.RANGE]?.id === '2' && params[fields.TERRIOTRY]
                    ? {CityId: params[fields.TERRIOTRY]?.id}
                    : {}
            ),
            ...(params[fields.RANGE]?.id === '3' && params[fields.TERRIOTRY]
                    ? {RegionId: params[fields.TERRIOTRY]?.id}
                    : {}
            ),
            ...(params[fields.RANGE]?.id === '4' && params[fields.TERRIOTRY]
                    ? {CountryId: params[fields.TERRIOTRY]?.id}
                    : {}
            ),
            ...(params[fields.TASK_TYPES] && params[fields.TASK_TYPES].length > 0
                    ?   {TaskTypeIds: params[fields.TASK_TYPES].map(({id}) => id)}
                    : {}),
            ...(params[fields.SEGMENT] && params[fields.SEGMENT].length > 0
                    ? {SegmentIds: params[fields.SEGMENT].map(({id}) => id)}
                    : {}
            )
            
        }
        dispatch(actions.setLocationsLoading(true));
        const locations = await get(`${moduleUrl}/taskSelection`, transformedParams) 

        const transformedLocations:ILocation[] = locations.map((location:any, i:number) => {
            return {
                id: location.customerId + i,
                description: location.customerName,
                address: location.address,
                country: location.country,
                region: location.region,
                customerId: location.customerId,
                customerName: location.customerName,
                location: [location.latitude, location.longitude],
                isActive: true,
                tasks: location.tasks.map((task:any, i:number) => {
                    return {
                        id: i,
                        taskType: task.typeName,
                        taskCode: task.typeCode,
                        address: location.address,
                        description: location.customerName,
                        duration: 15,
                        location: [location.latitude, location.longitude],
                        isActive: true,
                    
                        plannedFromUTC: task.plannedFromUTC,
                        plannedToUTC: task.plannedToUTC,
                        taskCustomerId: task.taskCustomerId,
                    }
                })
            }
        })

        dispatch(actions.setLocations(transformedLocations))

        return true;
    } catch (errors:any) {
        const error = getErrorMessage(errors, "Error occured while fetching tasks!");
        dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
        return false;
    } finally {
        dispatch(actions.setLocationsLoading(false));
    }
}

export const setTourPlan = (tours:IToursPlan | null) => actions.setTourPlan(tours)
export const setLocations = (locations:ILocation[]) => actions.setLocations(locations);
export const setSelectedTour = (tour:ITour | null) => actions.setSelectedTour(tour);

export const getTasks = (params:any) => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setTasksLoading(true));

        // dispatch(actions.setTasks(tasks));
    } catch (errors:any) {
        const error = getErrorMessage(errors, "Error occured while fetching tasks!");
        dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
    } finally {
        dispatch(actions.setTasksLoading(false));
    }
}

export const getVehicleTypes = () => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setVehicleTypesLoading(true));

        dispatch(actions.setVehicleTypes([
            {
                id: '1',
                name: 'Car',
                code: 'CAR',
            },
            {
                id: '2',
                name: 'Truck',
                code: 'TR',
            }
        ]));
    } catch (errors:any) {
        const error = getErrorMessage(errors, "Error occured while fetching vehicle types !");
        dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
    } finally {
        dispatch(actions.setVehicleTypesLoading(false))
    }
}


export const fetchProblem = (problem:any) => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setTourPlanLoading(true));
        // setTimeout(() => {
        //     const toursPlan:IToursPlan = {
        //         "statistic": {
        //             "cost": 18209.5758,
        //             "distance": 85758,
        //             "duration": 18091,
        //             "times": {
        //                 "driving": 8491,
        //                 "serving": 9600,
        //                 "waiting": 0,
        //                 "stopping": 0,
        //                 "break": 0
        //             }
        //         },
        //         "tours": [
        //             {
        //                 "vehicleId": "vehicle_1_2",
        //                 "typeId": "vehicle_1",
        //                 "stops": [
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T09:00:00Z",
        //                             "departure": "2024-11-26T09:00:00Z"
        //                         },
        //                         "load": [
        //                             0
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "departure",
        //                                 "type": "departure",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.6678224,
        //                                     "lng": 23.3444921
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T09:00:00Z",
        //                                     "end": "2024-11-26T09:00:00Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.6678224,
        //                             "lng": 23.3444921
        //                         },
        //                         "distance": 0
        //                     },
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T09:08:54Z",
        //                             "departure": "2024-11-26T09:38:54Z"
        //                         },
        //                         "load": [
        //                             46
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "job_2",
        //                                 "type": "pickup",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.645387,
        //                                     "lng": 23.3593959
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T09:08:54Z",
        //                                     "end": "2024-11-26T09:23:54Z"
        //                                 }
        //                             },
        //                             {
        //                                 "jobId": "job_3",
        //                                 "type": "pickup",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.645387,
        //                                     "lng": 23.3593959
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T09:23:54Z",
        //                                     "end": "2024-11-26T09:38:54Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.645387,
        //                             "lng": 23.3593959
        //                         },
        //                         "distance": 4668
        //                     },
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T09:48:52Z",
        //                             "departure": "2024-11-26T09:48:52Z"
        //                         },
        //                         "load": [
        //                             0
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "arrival",
        //                                 "type": "arrival",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.6678224,
        //                                     "lng": 23.3444921
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T09:48:52Z",
        //                                     "end": "2024-11-26T09:48:52Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.6678224,
        //                             "lng": 23.3444921
        //                         },
        //                         "distance": 9602
        //                     }
        //                 ],
        //                 "statistic": {
        //                     "cost": 2954.9602,
        //                     "distance": 9602,
        //                     "duration": 2932,
        //                     "times": {
        //                         "driving": 1132,
        //                         "serving": 1800,
        //                         "waiting": 0,
        //                         "stopping": 0,
        //                         "break": 0
        //                     }
        //                 },
        //                 "shiftIndex": 0
        //             },
        //             {
        //                 "vehicleId": "vehicle_1_4",
        //                 "typeId": "vehicle_1",
        //                 "stops": [
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T09:00:00Z",
        //                             "departure": "2024-11-26T09:00:00Z"
        //                         },
        //                         "load": [
        //                             0
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "departure",
        //                                 "type": "departure",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.6678224,
        //                                     "lng": 23.3444921
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T09:00:00Z",
        //                                     "end": "2024-11-26T09:00:00Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.6678224,
        //                             "lng": 23.3444921
        //                         },
        //                         "distance": 0
        //                     },
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T09:11:19Z",
        //                             "departure": "2024-11-26T09:21:19Z"
        //                         },
        //                         "load": [
        //                             23
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "job_6",
        //                                 "type": "pickup",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.6885777,
        //                                     "lng": 23.2999764
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T09:11:19Z",
        //                                     "end": "2024-11-26T09:21:19Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.6885777,
        //                             "lng": 23.2999764
        //                         },
        //                         "distance": 6170
        //                     },
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T09:28:20Z",
        //                             "departure": "2024-11-26T09:48:20Z"
        //                         },
        //                         "load": [
        //                             46
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "job_7",
        //                                 "type": "pickup",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.6554401,
        //                                     "lng": 23.2829972
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T09:28:20Z",
        //                                     "end": "2024-11-26T09:48:20Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.6554401,
        //                             "lng": 23.2829972
        //                         },
        //                         "distance": 10848
        //                     },
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T10:01:07Z",
        //                             "departure": "2024-11-26T10:01:07Z"
        //                         },
        //                         "load": [
        //                             0
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "arrival",
        //                                 "type": "arrival",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.6678224,
        //                                     "lng": 23.3444921
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T10:01:07Z",
        //                                     "end": "2024-11-26T10:01:07Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.6678224,
        //                             "lng": 23.3444921
        //                         },
        //                         "distance": 20108
        //                     }
        //                 ],
        //                 "statistic": {
        //                     "cost": 3691.0108,
        //                     "distance": 20108,
        //                     "duration": 3667,
        //                     "times": {
        //                         "driving": 1867,
        //                         "serving": 1800,
        //                         "waiting": 0,
        //                         "stopping": 0,
        //                         "break": 0
        //                     }
        //                 },
        //                 "shiftIndex": 0
        //             },
        //             {
        //                 "vehicleId": "vehicle_1_3",
        //                 "typeId": "vehicle_1",
        //                 "stops": [
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T09:00:00Z",
        //                             "departure": "2024-11-26T09:00:00Z"
        //                         },
        //                         "load": [
        //                             0
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "departure",
        //                                 "type": "departure",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.6678224,
        //                                     "lng": 23.3444921
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T09:00:00Z",
        //                                     "end": "2024-11-26T09:00:00Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.6678224,
        //                             "lng": 23.3444921
        //                         },
        //                         "distance": 0
        //                     },
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T09:08:54Z",
        //                             "departure": "2024-11-26T09:38:54Z"
        //                         },
        //                         "load": [
        //                             46
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "job_5",
        //                                 "type": "pickup",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.645387,
        //                                     "lng": 23.3593959
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T09:08:54Z",
        //                                     "end": "2024-11-26T09:23:54Z"
        //                                 }
        //                             },
        //                             {
        //                                 "jobId": "job_4",
        //                                 "type": "pickup",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.645387,
        //                                     "lng": 23.3593959
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T09:23:54Z",
        //                                     "end": "2024-11-26T09:38:54Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.645387,
        //                             "lng": 23.3593959
        //                         },
        //                         "distance": 4668
        //                     },
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T09:48:52Z",
        //                             "departure": "2024-11-26T09:48:52Z"
        //                         },
        //                         "load": [
        //                             0
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "arrival",
        //                                 "type": "arrival",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.6678224,
        //                                     "lng": 23.3444921
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T09:48:52Z",
        //                                     "end": "2024-11-26T09:48:52Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.6678224,
        //                             "lng": 23.3444921
        //                         },
        //                         "distance": 9602
        //                     }
        //                 ],
        //                 "statistic": {
        //                     "cost": 2954.9602,
        //                     "distance": 9602,
        //                     "duration": 2932,
        //                     "times": {
        //                         "driving": 1132,
        //                         "serving": 1800,
        //                         "waiting": 0,
        //                         "stopping": 0,
        //                         "break": 0
        //                     }
        //                 },
        //                 "shiftIndex": 0
        //             },
        //             {
        //                 "vehicleId": "vehicle_1_1",
        //                 "typeId": "vehicle_1",
        //                 "stops": [
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T09:00:00Z",
        //                             "departure": "2024-11-26T09:00:00Z"
        //                         },
        //                         "load": [
        //                             0
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "departure",
        //                                 "type": "departure",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.6678224,
        //                                     "lng": 23.3444921
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T09:00:00Z",
        //                                     "end": "2024-11-26T09:00:00Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.6678224,
        //                             "lng": 23.3444921
        //                         },
        //                         "distance": 0
        //                     },
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T09:13:01Z",
        //                             "departure": "2024-11-26T09:53:01Z"
        //                         },
        //                         "load": [
        //                             46
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "job_9",
        //                                 "type": "pickup",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.6554401,
        //                                     "lng": 23.2829972
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T09:13:01Z",
        //                                     "end": "2024-11-26T09:33:01Z"
        //                                 }
        //                             },
        //                             {
        //                                 "jobId": "job_8",
        //                                 "type": "pickup",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.6554401,
        //                                     "lng": 23.2829972
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T09:33:01Z",
        //                                     "end": "2024-11-26T09:53:01Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.6554401,
        //                             "lng": 23.2829972
        //                         },
        //                         "distance": 8955
        //                     },
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T10:05:48Z",
        //                             "departure": "2024-11-26T10:05:48Z"
        //                         },
        //                         "load": [
        //                             0
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "arrival",
        //                                 "type": "arrival",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.6678224,
        //                                     "lng": 23.3444921
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T10:05:48Z",
        //                                     "end": "2024-11-26T10:05:48Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.6678224,
        //                             "lng": 23.3444921
        //                         },
        //                         "distance": 18215
        //                     }
        //                 ],
        //                 "statistic": {
        //                     "cost": 3971.8215,
        //                     "distance": 18215,
        //                     "duration": 3948,
        //                     "times": {
        //                         "driving": 1548,
        //                         "serving": 2400,
        //                         "waiting": 0,
        //                         "stopping": 0,
        //                         "break": 0
        //                     }
        //                 },
        //                 "shiftIndex": 0
        //             },
        //             {
        //                 "vehicleId": "vehicle_1_1",
        //                 "typeId": "vehicle_1",
        //                 "stops": [
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T14:05:00Z",
        //                             "departure": "2024-11-26T14:05:00Z"
        //                         },
        //                         "load": [
        //                             0
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "departure",
        //                                 "type": "departure",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.7109623,
        //                                     "lng": 23.3438797
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T14:05:00Z",
        //                                     "end": "2024-11-26T14:05:00Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.7109623,
        //                             "lng": 23.3438797
        //                         },
        //                         "distance": 0
        //                     },
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T14:28:14Z",
        //                             "departure": "2024-11-26T14:58:14Z"
        //                         },
        //                         "load": [
        //                             46
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "job_0",
        //                                 "type": "pickup",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.6634255,
        //                                     "lng": 23.2224589
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T14:28:14Z",
        //                                     "end": "2024-11-26T14:43:14Z"
        //                                 }
        //                             },
        //                             {
        //                                 "jobId": "job_1",
        //                                 "type": "pickup",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.6634255,
        //                                     "lng": 23.2224589
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T14:43:14Z",
        //                                     "end": "2024-11-26T14:58:14Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.6634255,
        //                             "lng": 23.2224589
        //                         },
        //                         "distance": 14130
        //                     },
        //                     {
        //                         "time": {
        //                             "arrival": "2024-11-26T15:21:52Z",
        //                             "departure": "2024-11-26T15:21:52Z"
        //                         },
        //                         "load": [
        //                             0
        //                         ],
        //                         "activities": [
        //                             {
        //                                 "jobId": "arrival",
        //                                 "type": "arrival",
        //                                 address: 'Some address',
        //                                 "location": {
        //                                     "lat": 42.7109623,
        //                                     "lng": 23.3438797
        //                                 },
        //                                 "time": {
        //                                     "start": "2024-11-26T15:21:52Z",
        //                                     "end": "2024-11-26T15:21:52Z"
        //                                 }
        //                             }
        //                         ],
        //                         "location": {
        //                             "lat": 42.7109623,
        //                             "lng": 23.3438797
        //                         },
        //                         "distance": 28231
        //                     }
        //                 ],
        //                 "statistic": {
        //                     "cost": 4636.8231,
        //                     "distance": 28231,
        //                     "duration": 4612,
        //                     "times": {
        //                         "driving": 2812,
        //                         "serving": 1800,
        //                         "waiting": 0,
        //                         "stopping": 0,
        //                         "break": 0
        //                     }
        //                 },
        //                 "shiftIndex": 1
        //             }
        //         ],
        //         "notices": [
        //             {
        //                 "code": "allJobsAndVehiclesHaveTheSameSkills",
        //                 "title": "All jobs and vehicle have the same skills (CDE,MSA,VIS,EM)",
        //                 "action": "Check whether the jobs and vehicle types use the correct skills"
        //             }
        //         ]
        //     }
            
        //     console.log(toursPlan)
        //     dispatch(actions.setTourPlan({
        //         ...toursPlan,
        //         tours: toursPlan.tours.map((tour, i) => ({
        //             ...tour,
        //             color: ROUTE_COLORS[i] ? ROUTE_COLORS[i] : ROUTE_COLORS[Math.floor(Math.random() * 12)]
        //         }))
        //     }));
        // }, 1000)
        const headers = {
            Authorization: 'Bearer eyJhbGciOiJSUzUxMiIsImN0eSI6IkpXVCIsImlzcyI6IkhFUkUiLCJhaWQiOiJMSW1Md2FHRGVGbEk1TUJnZlU1MyIsImlhdCI6MTczMzk5NTM1NSwiZXhwIjoxNzM0MDgxNzU1LCJraWQiOiJqMSJ9.ZXlKaGJHY2lPaUprYVhJaUxDSmxibU1pT2lKQk1qVTJRMEpETFVoVE5URXlJbjAuLkpOSmdFc0x3bmtFNFpXZ3loLVNrencuZ0pBb0E2WUkteWFYTXJOM2JaLWR1ZGhPRktlTHZNWTZKcm1uVWtJTldFdmxQV0ZNTERfYndaSVNTdjkxNkQtWHE4OEFpZ3YyZFg2ZS03ZVY4a0swcjd2TWN3TWtCdC1HQ2NFUmFTc09paE8wWU9rOEpOcFpCLWh5ek1HLTNTWVlLTVZpVkFFMXk1RjgwSVpRdXJiZU9xYlpIQTBpbWx3Q3dyWWtCYU0tdllRLnVxWTJKZE5JX2RENmZwY3Ixb1RQYmp4T0EyRjlFX0tibmhzRnNYUHpXZUk.EguL8M5lOFxVnXV_T6Z10zvE-bvRN71Nl1h_9UieHgQfEsfSzAHq2YXVEYyUuXvEUrCaEP8bhd-I_Ri1DoVIZxaSgSRrVx40aic0SzkZY7rbCoQJn95kO0nyTpRWr9w9sWFvWmu-xHij3onNtVGAPBVRHnZaaPGI49yMoO9iJPqL2SjmBgXz1I3aIldoon0w1XONIW_-hUWFm6qJcSGoviwvDS6ioaRYkKfgh5eJFzPBa4Inj1-0CH_uRQp_Slz3qwxMuBxqQrYOljrrqkUE4ISy-RwysJt6Ilm3JlvuoJNeGBJKDMSqBPQAQd6TqU3hr3dipOjnFImNdm9cUzpvSA'
        }

        const problemSummary = await postHere(`/v3/problems`, {}, problem, headers);
        const asyncSolve = await postHere(`/v3/problems/async`, {}, problem, headers);

        let currentStatus = ''

        const callStatus = async (href:string) => {
            const result = await getHere(href, {}, {}, headers)
            
            if (result.status !== currentStatus) {
                currentStatus = result.status;
                dispatch(actions.setTourPlanStatus(result.status));
            }        
            
            if (result.status === 'pending' || result.status === 'inProgress') {
                setTimeout(() => {
                    callStatus(href)
                }, 6000)
            } else if (result.status === 'success') {
                const toursPlan:IToursPlan = await getHere(`/v3/problems/${result.resource.resourceId}/solution`, {}, {}, headers);
                
                console.log(toursPlan)
                dispatch(actions.setTourPlan({
                    ...toursPlan,
                    tours: toursPlan.tours.map((tour, i) => ({
                        ...tour,
                        // color: ROUTE_COLORS[Math.floor(Math.random() * 12)]
                        color: ROUTE_COLORS[i] ? ROUTE_COLORS[i] : ROUTE_COLORS[Math.floor(Math.random() * 12)]
                    }))
                }));
            } else {

            }
        }
        callStatus(`/v3/status/${asyncSolve.statusId}`)
        // console.log({asyncSolve, problemSummary})
    } catch (errors:any) {

    } finally {

    }
}