import { createSlice } from "@reduxjs/toolkit";
import { put, delay, takeLatest } from "redux-saga/effects";
import SagasManager from "./SagasManager";
import { getCurrentUserOrganizationUnit, getCurrentAccessToken } from "../app/user-access/organizationUnitStorage";
import { swaggerClient } from "../api/swaggerApiConnect";
import { authProvider } from "../app/auth/authProvider";
import { constructInitialFilters } from "../app/components/common/filters/utils";

//TODO: Delete this file after migrating all modules to Typescript

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  entitiesSelect: [],
  itemForEdit: null,
  lastError: null,
  validationErrors: {},
  itemId: null,
  filters: {}
};

export const callTypes = {
  list: "listLoading",
  action: "actionsLoading",
};

export const getDefaultSlice = (name, customReducers, customInitialState) =>
  createSlice({
    name: name,
    initialState: { ...initialState, ...customInitialState },
    reducers: {
      catchError: (state, action) => {
        state[action.payload.errorProp] = Object.values(action.payload.error);

        state[action.payload.callType] = false;
      },
      startCall: (state, action) => {
        state.error = null;
        state.selectError = null;
        if (action.payload.callType === action.payload.callType.actionsLoading) {
          state.entities = [];
          state.entitiesSelect = [];
          state.totalCount = 0;
          state.itemForEdit = null;
          state.itemId = null;
        }
        state[action.payload.callType] = true;
      },
      itemFetched: (state, action) => {
        state.actionsLoading = false;
        state.listLoading = false;
        state.itemForEdit = action.payload.itemForEdit;
        state.error = null;
        state.selectError = null;
        state.itemId = null;
      },
      itemsFetched: (state, action) => {
        const { totalCount, entities } = action.payload;
        state.actionsLoading = false;
        state.listLoading = false;
        state.error = null;
        state.selectError = null;
        state.entities = entities;
        state.totalCount = totalCount;
        state.itemId = null;
      },
      itemCreated: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
        state.selectError = null;
        state.itemId = action.payload.item;
        //state.entities.push(action.payload.item);
      },
      itemUpdated: (state, action) => {
        state.error = null;
        state.selectError = null;
        state.actionsLoading = false;
        state.itemId = null;
        //state.entities = state.entities.map((entity) => {
        //  if (entity.id === action.payload.item.id) {
        //    return action.payload.item;
        //  }
        //  return entity;
        //});
      },
      itemDeleted: (state, action) => {
        state.error = null;
        state.selectError = null;
        state.actionsLoading = false;
        state.entities = state.entities.filter(el => el.id !== action.payload.id);
      },
      itemsDeleted: (state, action) => {
        state.error = null;
        state.selectError = null;
        state.actionsLoading = false;
        state.entities = state.entities.filter(el => !action.payload.ids.includes(el.id));
      },
      itemsStatusUpdated: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
        state.selectError = null;
        const { ids, status } = action.payload;
        state.entities = state.entities.map(entity => {
          if (ids.findIndex(id => id === entity.id) > -1) {
            entity.status = status;
          }
          return entity;
        });
      },
      customItemsFetched: (state, action) => {
        const { totalCount, entities, entitiesProp, loadingProp, totalProp } = action.payload;

        state[entitiesProp] = entities;
        state[loadingProp] = false;
        if (totalProp) state[totalProp] = totalCount;

        state.error = null;
        state.selectError = null;
        state.itemId = null;
      },
      clear: (state, action) => {
        state.listLoading = false;
        state.actionsLoading = false;
        state.totalCount = 0;
        state.entities = [];
        state.entitiesSelect = [];
        state.itemForEdit = null;
        state.lastError = null;
        state.itemId = null;
      },
      clearItem: (state) => {
        state.itemsLoading = false;
        state.actionsLoading = false;
        state.dropDownItems = [];
        state.itemForEdit = null;
      },
      clearError: (state, action) => {
        state.error = null;
        state.selectError = null;
      },
      setValidationErrors: (state, action) => {
        state.validationErrors = action.payload;
      },
      setFilter: (state, action) => {
        state.filters = action.payload
      },
      ...customReducers,
    },
  });

export const requestErrorHandling = (error, defaultMessage) => {
  let errors = { "": [defaultMessage] };
  if (error.response && error.response.body?.errors)
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    errors = error.response.body.errors;
  if (error.response && error.response.body?.error) return errors;
  if (error.request)
    // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
    errors = error.request;
  if (error.message)
    // Something happened in setting up the request that triggered an Error
    return errors;

  return errors;
};

export const getResponseErrorMessage = (error, defaultMessage) => {
  let message = defaultMessage;
  if (error?.response?.body?.errors) {
    for (const e in error.response.body.errors) {
      message = [];
      message.push(error.response.body.errors[e]);
    }
  }
  if (error?.response?.body?.error) message = error.response.body.error;
  if (error.request) message = error.request;
  if (error.message) message = error.message;

  return message;
};

export const getErrorMessage = (error, defaultMessage) => {
  let message = defaultMessage;

  if (error.errors) {
    message = [];
    Object.values(error.errors).forEach((e) => {
      if (Array.isArray(e)) {
        e.forEach((m) => message.push(m))
      } else {
        message.push(e);
      }
    });
  }

  if (error.Errors) {
    message = [];
    Object.values(error.Errors).forEach((e) => {
      if (Array.isArray(e)) {
        e.forEach((m) => message.push(m))
      } else {
        message.push(e);
      }
    });
  }

  if (error.message) {
    message = error.message + ' ';
  }

  if (error.Messages) {
    message += error.Messages.map((e) => e + ' ');
  }

  if (error.Exception) {
    message += ` ${error.Exception} `
  }

  return message;
};

export const convertCreateItemParams = propertyList => item => {
  let params = {};
  if (propertyList) propertyList.map(property => (params[property] = item[property]));
  return params;
};

export const fetchItemsSwagger = (operationID, actions, entitiesProp = "entities", errorProp = "error") =>
  fetchCustomItemsSwagger(operationID, actions, entitiesProp, callTypes.list, "totalCount", errorProp);

export const fetchItemsWithDelaySwagger = (operationID, actions, actionType, errorProp = "error") =>
  fetchCustomItemsWithDelaySwagger(
    operationID,
    actions,
    actionType,
    "entities",
    callTypes.list,
    "totalCount",
    errorProp,
  );

export const clear = actions => dispatch => {
  dispatch(actions.clear());
};

export const clearError = actions => dispatch => {
  dispatch(actions.clearError());
};

/** 
 * @desc:     Reset Item State (internal)
 **/
 export const clearItem = (actions) => (dispatch) => {
  dispatch(actions.clearItem());
};

export const setValidationErrors = actions => dispatch => {
  dispatch(actions.setValidationErrors());
};

export const fetchItemSwagger = (operationID, actions, mapItemFunc, errorProp = "error") => id => async dispatch => {
  if (!id) {
    return dispatch(actions.itemFetched({ itemForEdit: undefined }));
  }

  dispatch(actions.itemFetched({ itemForEdit: undefined }));

  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  let params = {
    Id: id,
    OrganizationUnit: userOrgUnit,
  };

  dispatch(actions.startCall({ callType: callTypes.action }));
  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(data => {
        let itemForEdit = data.body;
        if (mapItemFunc) itemForEdit = mapItemFunc(data.body);

        dispatch(actions.itemFetched({ itemForEdit }));
      })
      .catch(errors => {
        let error = requestErrorHandling(errors, "Error occurred during fetching the item!");
        dispatch(actions.catchError({ error, callType: callTypes.action, errorProp }));
      }),
  );
};

export const setFilter = (filters, actions) => dispatch => {
 dispatch(actions.setFilter(filters));
};

export const getFiltersSwagger = (operationID, actions, errorProp = "error") => _ => async (dispatch) => {
  const userOrgUnit = getCurrentUserOrganizationUnit();
  const response = await getCurrentAccessToken();
  let params = {
    OrganizationUnit: userOrgUnit,
  };
  
  dispatch(actions.startCall({ callType: callTypes.action }));
  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(data => {
        const item = data.body;
        dispatch(actions.setFilter(constructInitialFilters(item)));
        return true;
      })
      .catch(errors => {
        let error = requestErrorHandling(errors, "Error occurred during deleting the item!");
        dispatch(actions.catchError({ error, callType: callTypes.action, errorProp }));

        return false;
      }),
  );
}

export const deleteItemSwagger = (operationID, actions, errorProp = "error") => id => async dispatch => {
  const userOrgUnit = getCurrentUserOrganizationUnit();
  const response = await getCurrentAccessToken();

  let params = {
    Id: id,
    OrganizationUnit: userOrgUnit,
  };

  dispatch(actions.startCall({ callType: callTypes.action }));
  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(data => {
        const item = data.body;
        dispatch(actions.itemDeleted({ item }));
        return true;
      })
      .catch(errors => {
        let error = requestErrorHandling(errors, "Error occurred during deleting the item!");
        dispatch(actions.catchError({ error, callType: callTypes.action, errorProp }));

        return false;
      }),
  );
};

export const createItemSwagger = (operationID, actions, mapItemFunc, errorProp = "error", requestInterceptor) => item => async dispatch => {
  let params = {};
  if (mapItemFunc) params = mapItemFunc(item);

  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  let headerParam = { OrganizationUnit: userOrgUnit };

  dispatch(actions.startCall({ callType: callTypes.action }));
  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: headerParam,
        requestBody: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
        ...(requestInterceptor ? {requestInterceptor} : {})
      })
      .then(data => {
        const item = data.body;
        dispatch(actions.itemCreated({ item }));
        return true;
      })
      .catch(err => {
        let defaultMessage = err.status === 413
          ? 'Attachment total size exceeds the allowable limit of 50 MB!'
          : "Error occurred during creating the item!"

        let error = requestErrorHandling(err, defaultMessage);
        dispatch(actions.catchError({ error, callType: callTypes.action, errorProp }));

        return false;
      }),
  );
};

export const updateItemSwagger = (operationID, actions, mapItemFunc, errorProp = "error", requestInterceptor) => (
  id,
  item,
) => async dispatch => {
  let body = item;
  if (mapItemFunc) body = mapItemFunc(item);

  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  let params = {
    OrganizationUnit: userOrgUnit,
  };

  dispatch(actions.startCall({ callType: callTypes.action }));

  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        requestBody: body,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        ...(requestInterceptor ? {requestInterceptor} : {})
      })
      .then(data => {
        const item = data.body;
        dispatch(actions.itemUpdated({ item }));
        return item || true;
      })
      .catch(err => {
        let defaultMessage = err.status === 413
          ? 'Attachment total size exceeds the allowable limit of 50 MB!'
          : "Error occurred during updating the item!"

        let error = requestErrorHandling(err, defaultMessage);
        dispatch(actions.catchError({ error, callType: callTypes.action, errorProp }));
        
      }),
  );
};

/*export const updateItemsStatus = (crudInstance, actions) => (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return crudInstance
    .updateItemsStatus(ids, status)
    .then(() => {
      dispatch(actions.itemsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update items status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};*/

export const fetchCustomItemsWithDelaySwagger = (
  operationID,
  actions,
  actionType,
  entitiesProp,
  loadingProp,
  totalProp,
  errorProp,
) => {
  const fetchItemsWorker = function*(action) {
    yield delay(60);
    const { payload: queryParams } = action;

    try {
      yield put(actions.startCall({ callType: loadingProp }));
      let entities, totalCount;

      let params = {};
      queryParams
        ? (params = {
            ...queryParams,
            Filter: queryParams["Filter"],
            Sort: queryParams["Sort"],
            Page: queryParams["Page"],
            Size: queryParams["Size"],
          })
        : (params = {});

      const response = yield getCurrentAccessToken();
      const userOrgUnit = getCurrentUserOrganizationUnit();

      params["OrganizationUnit"] = userOrgUnit;

      yield swaggerClient.then(client =>
        client
          .execute({
            spec: client.spec,
            operationId: operationID,
            parameters: params,
            securities: {
              authorized: {
                bearerAuth: response.accessToken,
              },
            },
            responseContentType: "application/json",
          })
          .then(data => {
            if ("items" in data.body) {
              entities = data.body.items;
            } else {
              entities = data.body;
            }
            if ("totalCount" in data.body) totalCount = data.body.totalCount;
          }),
      );

      yield put(actions.customItemsFetched({ entities, totalCount, entitiesProp, loadingProp, totalProp }));
    } catch (errors) {
      let error = requestErrorHandling(errors, `Can't load ${entitiesProp}`);

      yield put(actions.catchError({ error, callType: loadingProp, errorProp }));
    }
  };

  const fetchItemsWatcher = function*() {
    yield takeLatest(actionType, fetchItemsWorker);
  };

  SagasManager.addSagaToRoot(fetchItemsWatcher);

  return filter => ({
    type: actionType,
    payload: filter,
  });
};

export const fetchCustomItemsSwagger = (
  operationID,
  actions,
  entitiesProp,
  loadingProp,
  totalProp,
  errorProp,
) => queryParams => async dispatch => {
  dispatch(actions.startCall({ callType: loadingProp }));
  let entities, totalCount;

  let params = queryParams ?? {};

  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  params["OrganizationUnit"] = userOrgUnit;

  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(data => {
        if ("items" in data.body) {
          entities = data.body.items;
        } else {
          entities = data.body;
        }
        if ("totalCount" in data.body) totalCount = data.body.totalCount;
        dispatch(actions.customItemsFetched({ entities, totalCount, entitiesProp, loadingProp, totalProp }));
      })
      .catch(errors => {
        let error = requestErrorHandling(errors, `Can't load ${entitiesProp}`);
        dispatch(actions.catchError({ error, callType: loadingProp, errorProp }));
        authProvider.logoutRedirect();
      }),
  );
};
