export const PATHS = {
    ROOT: 'route-planning',
    ROUTES_LIST: 'routes-list',
    ROUTE_PREVIEW: 'preview',
}

// List page
export const DATE = 'date';
export const ROLE = 'role';
export const ASSIGNED_TO = 'assigned_to';
export const DURATION = 'duration';
export const DISTANCE = 'distance';
export const STOPS = 'stops';
export const TASKS = 'tasks';

// Task creation
export const TASK_SELECTION_FILTER_TAB = 'TASK_SELECTION_FILTER_TAB';
export const RANGE = 'range';
export const ID = 'id';
export const NAME = 'name';
export const CODE = 'code';
export const TERRIOTRY = 'territory';
export const ROLES = 'roles';
export const INCLUDE_ASSIGNED= 'includeAssigned';
export const TASK_TYPES= 'taskTypes';
export const SEGMENT= 'segments';

export const TASK_SELECTION_TAB = 'TASK_SELECTION_TAB';

export const ROUTE_SETUP_TAB = 'ROUTE_SETUP_TAB';
export const START_LOCATION = 'startLocation';
export const VEHICLE_TYPE = 'vehicleType';
export const NUMBER_OF_VEHICLES = 'numberOfVehicles';
export const CAPACITY_OF_VEHICLE = 'capacityOf_Vehicle';

export const MAX_DISTANCE = 'maxDistance';
export const ENABLE_MAX_DISTANCE = 'enableMaxDistance';

export const MAX_SHIFT_TIME = 'maxShiftTime';
export const ENABLE_MAX_SHIFT_TIME = 'enableMaxShiftTime';

export const SHIFT_START_TIME = 'shiftStartTime';
export const SHIFT_END_TIME = 'shiftEndTime';

export const TOUR_END = 'tourEnd';
export const START_POINT = 'startPoint';
export const END_POINT = 'endPoint';
export const CUSTOM = 'custom';

export const TABLE_COLUMNS = [
    {
        dataField: DATE,
        text: 'Date',
        sort: true,
        style: { width: "5%" },
    },
    {
        dataField: ROLE,
        text: 'Role',
        sort: true,
        style: { width: "5%" },
    },
    {
        dataField: ASSIGNED_TO,
        text: 'Assigned to',
        sort: true,
        style: { width: "35%" },
    },
    {
        dataField: DURATION,
        text: 'Duration',
        sort: true,
        style: { width: "8%" },
    },
    {
        dataField: DISTANCE,
        text: 'Distance',
        sort: true,
        style: { width: "8%" },
    },
    {
        dataField: STOPS,
        text: 'Stops',
        sort: true,
        style: { width: "8%" },
    },
    {
        dataField: TASKS,
        text: 'Tasks',
        sort: true,
        style: { width: "8%" },
    },
];

export const ROUTE_COLORS = [
    "#5F5CF1",
    "#FF9505",
    "#3699FF",
    "#1BC5BD",
    "#F64E60",
    "#EB4B98",
    "#8950FC",
    "#7B4B94",
    "#084887",
    "#136F63",
    "#679436",
    "#9FD356",
]

export const TASK_TYPE_ICONS:any = {
    EM: 'ic-maintenance',
    CDE: 'ic-customer-development',
    MSA: 'ic-market-scan',
    SUR: 'ic-survey',
    VIS: 'ic-visit',
    NCUS: 'ic-new-customer',
    CCUS: 'ic-change-of-customer',
    ORD: 'ic-order',
    RPL: 'ic-replenishment',
    EPA: 'ic-placement-and-activation',
    ERD: 'ic-removal-and-deactivation',
    SCI: 'ic-3d-planner',
}